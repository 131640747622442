<template>
    <svg viewBox="0 0 600 170" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M344.168 155.192L316.808 133.608L341.736 68.4L338.848 57.304C359.368 48.944 379.28 45.6 400.256 45.752L404.36 67.488L367.12 76.456L360.584 94.544C368.792 92.72 377.304 91.504 386.12 90.744L389.312 107.616L352.376 117.04L347.816 129.808L383.232 122.512L387.488 144.552L344.168 155.192ZM460.083 155.04L431.507 121.448L419.955 155.04L392.595 143.488L421.323 68.248L418.587 57.456C436.067 49.704 457.499 46.36 479.083 45.6L495.499 74.936C491.243 89.832 480.907 104.12 463.579 114.76L482.275 134.672L460.083 155.04ZM438.195 101.992C455.067 97.28 465.403 86.032 471.483 69.008L447.315 75.696L438.195 101.992ZM554.334 155.04C532.294 155.344 509.19 152.76 486.086 145.16L493.534 117.192C510.254 122.512 527.582 126.008 544.606 129.048C524.846 120.384 509.646 111.568 499.31 101.536C500.07 98.8 500.83 96.216 501.742 93.784C511.166 66.12 529.406 48.944 548.558 45.6C562.39 49.704 578.046 56.392 587.926 63.688L573.334 105.64L549.318 98.952L559.046 67.184C545.366 72.048 536.246 82.84 530.318 99.256L565.582 121.144L554.334 155.04Z" fill="currentColor"/>
        <path d="M237.764 37.4749C239.476 29.6902 247.174 24.7669 254.959 26.4785L316.983 40.1151C324.767 41.8267 329.691 49.5249 327.979 57.3097L308.803 144.529C307.092 152.314 299.393 157.237 291.609 155.525L229.585 141.889C221.8 140.177 216.877 132.479 218.588 124.694L237.764 37.4749Z" fill="black" fill-opacity="0.2"/>
        <rect x="1.5" y="32.3308" width="110" height="139" rx="16" transform="rotate(-8 1.5 32.3308)" fill="currentColor"/>
        <path d="M24.3134 74.3833L21.6565 55.4782L96.7706 44.9216L99.4275 63.8267L73.433 67.48L82.922 134.998L59.797 138.248L50.3079 70.73L24.3134 74.3833Z" fill="white"/>
        <rect x="216.5" y="17.0219" width="110" height="139" rx="16" transform="rotate(8 216.5 17.0219)" fill="currentColor"/>
        <path d="M279.022 80.8788C278.866 79.3364 278.473 77.9472 277.844 76.7112C277.218 75.447 276.37 74.3381 275.299 73.3843C274.26 72.4063 272.995 71.6117 271.503 71.0004C270.043 70.3649 268.399 69.9187 266.57 69.6617C262.632 69.1081 259.138 69.5781 256.087 71.0716C253.066 72.569 250.574 74.9872 248.613 78.3263C246.68 81.6694 245.362 85.8448 244.658 90.8524C243.946 95.9162 244.027 100.346 244.899 104.14C245.771 107.935 247.438 110.981 249.898 113.277C252.358 115.574 255.613 117.007 259.664 117.576C263.237 118.078 266.278 117.975 268.788 117.266C271.325 116.561 273.319 115.335 274.77 113.588C276.22 111.841 277.127 109.674 277.491 107.085L281.483 108.077L261.734 105.301L264.082 88.5905L302.399 93.9756L300.715 105.96C299.607 113.837 296.987 120.34 292.852 125.468C288.75 130.571 283.563 134.203 277.292 136.363C271.054 138.498 264.165 139.036 256.625 137.976C248.213 136.794 241.08 133.97 235.224 129.504C229.368 125.037 225.118 119.247 222.475 112.134C219.859 105.025 219.192 96.9126 220.473 87.7976C221.477 80.6519 223.45 74.4743 226.391 69.2647C229.36 64.0592 233.053 59.8302 237.469 56.5779C241.889 53.2975 246.781 51.0446 252.146 49.819C257.515 48.5654 263.111 48.3478 268.934 49.1662C274.055 49.8858 278.706 51.2854 282.889 53.3651C287.104 55.4206 290.712 58.0219 293.712 61.169C296.745 64.2918 299.047 67.8141 300.619 71.7358C302.191 75.6575 302.897 79.8162 302.738 84.2119L279.022 80.8788Z" fill="white"/>
        <path d="M91.6898 137.588C92.0679 146.082 99.0237 152.797 107.526 152.876L213.848 153.86C222.345 153.938 229.422 147.361 229.964 138.881L236.412 38.0208C237.001 28.8015 229.683 21 220.444 21H103.228C94.1123 21 86.8385 28.6047 87.2439 37.7115L91.6898 137.588Z" fill="black" fill-opacity="0.2"/>
        <rect x="95.5" width="133" height="168" rx="16" fill="currentColor"/>
        <path d="M209.619 71.3636H181.58C181.381 69.0436 180.85 66.9389 179.989 65.0497C179.16 63.1605 178 61.5365 176.509 60.1776C175.05 58.7855 173.277 57.7249 171.189 56.9957C169.101 56.2334 166.731 55.8523 164.08 55.8523C159.439 55.8523 155.512 56.9792 152.297 59.233C149.115 61.4867 146.696 64.7183 145.038 68.9276C143.414 73.1368 142.602 78.1913 142.602 84.0909C142.602 90.322 143.431 95.5421 145.088 99.7514C146.778 103.928 149.214 107.076 152.396 109.197C155.578 111.286 159.406 112.33 163.881 112.33C166.433 112.33 168.72 112.015 170.741 111.385C172.763 110.722 174.52 109.777 176.011 108.551C177.503 107.325 178.713 105.85 179.641 104.126C180.602 102.37 181.248 100.398 181.58 98.2102L209.619 98.4091C209.288 102.718 208.078 107.109 205.99 111.584C203.902 116.025 200.936 120.135 197.091 123.913C193.279 127.659 188.556 130.675 182.922 132.962C177.287 135.249 170.741 136.392 163.284 136.392C153.938 136.392 145.552 134.387 138.128 130.376C130.737 126.366 124.887 120.466 120.578 112.678C116.303 104.889 114.165 95.3598 114.165 84.0909C114.165 72.7557 116.352 63.2102 120.727 55.4545C125.102 47.6657 131.002 41.7827 138.426 37.8054C145.85 33.795 154.136 31.7898 163.284 31.7898C169.714 31.7898 175.63 32.6681 181.033 34.4247C186.435 36.1813 191.175 38.75 195.251 42.1307C199.328 45.4782 202.609 49.6046 205.095 54.5099C207.581 59.4152 209.089 65.0331 209.619 71.3636Z" fill="white"/>
    </svg>
</template>
